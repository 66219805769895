import React from "react"
import { useTranslation } from "react-i18next"
import LoadingPage from "../LoadingPage"

import OrderItem from "./OrderItem"
import "./OrderPage.scss"

function OrderList(props) {
    const { t, i18n } = useTranslation("main");

  var { innerWidth: width } = window

  function buildOptionList(el) {
      const options = []
      if (el.ComboID && el.ComboID !== '00000000-0000-0000-0000-000000000000') {
          el.Items.forEach((item) => {
              item.SelectedOptions.forEach(el => options.push(el.Name))
          })
      } else
      if (el.ItemID) {
          el.SelectedOptions.forEach(el => options.push(el.Name))
      }
      return options.join("+")
  }

    function renderOrderListInfo() {
        if (!props.hideTotal) {
            if (width > 1023) {
                return (
                    <div className="order-list-info-desctop">
                        <div style={{ marginLeft: "10px" }}>
                            {/* <span>חולשמ</span>
            <span className="order-list-info-optional-sum">{t("currency")}107.00</span> */}
                        </div>
                        <div>
                            <span>{t("order.total")}</span>
                            <span style={{ marginRight: "10px" }}>{t("currency")}{props.chargeAmount}</span>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="order-list-info">
                        <div>
                            {/* <span>{t("currency")}107.00</span>
                        <span><span className="order-list-info-count">({props.orders.data.length})</span> סה״כ</span> */}
                        </div>
                        <div>
                            <span>{t("order.total")}</span>
                            <span>{t("currency")}{props.chargeAmount}</span>
                        </div>
                    </div>
                )
            }
        }
    }

  function renderOrderList() {
    if (width > 1023) {
      return (
        <>
          <div className="order-column-headers">
            <span style={{ width: "59%", marginRight: "3%" }}>
              {t("order.list.item_info")}
            </span>
            <span style={{ width: "22%" }}>
              {t("order.list.quantity")}
            </span>
            <span>
              {t("order.list.price")}
            </span>
            {/* <span style={{marginRight: '16%'}}>כמות </span>   */}
          </div>
          {props.orders.data?.map((el, index) => (
            <OrderItem
              key={`${index}-${props.hideTotal}`}
              title={el.ItemName}
              quantity={el.Quantity}
              options={buildOptionList(el)}
              type={el.MealTypeName}
              date={el.OrderToDate}
              supplier={el.SupplierName}
              bordered={
                index === props.orders.data.length - 1 ? "" : "bordered"
              }
              cost={!props.hideTotal ? el.EmploeeAmount : 0}
              budgetCost={el.BudgetAmount}
              elIndex={index + 1}
              image={
                el.ItemImages && el.ItemImages.length
                  ? el.ItemImages[0].URL
                  : ""
              }
              deleteItem={props.deleteItem}
              editItem={() =>
                props.setEditItem({
                  ...el,
                  OrderItemQuantity: el.Quantity,
                  OrderItemNotes: el.Notes
                })
              }
            />
          ))}
        </>
      )
    } else {
      return (
        <>
          <span className="order-list-details">{t("order.details")}</span>
          {props.orders.data?.map((el, index) => (
            <OrderItem
              key={`${index}-${props.hideTotal}`}
              title={el.ItemName}
              quantity={el.Quantity}
              options={buildOptionList(el)}
              type={el.MealTypeName}
              date={el.OrderToDate}
              supplier={el.SupplierName}
              bordered={
                index === props.orders.data.length - 1 ? "" : "bordered"
              }
              cost={!props.hideTotal ? el.EmploeeAmount : 0}
              elIndex={index + 1}
              image={
                el.ItemImages && el.ItemImages.length
                  ? el.ItemImages[0].URL
                  : ""
              }
              deleteItem={props.deleteItem}
              editItem={() =>
                props.setEditItem({
                  ...el,
                  OrderItemQuantity: el.Quantity,
                  OrderItemNotes: el.Notes
                })
              }
            />
          ))}
        </>
      )
    }
  }

  return (
    <div
      className="order-list"
      style={{ minHeight: `${props.orders.isLoading ? "300px" : "initial"}` }}
    >
      {props.orders.isLoading ? (
        <LoadingPage />
      ) : (
        <>
          {renderOrderList()}
          {renderOrderListInfo()}
        </>
      )}
    </div>
  )
}

export default OrderList
