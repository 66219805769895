import optionIcons from "../OptionIconsImages";
import selectedIcon from "../../images/dish/selected_icon.svg";
import { useTranslation } from "react-i18next"
import React from "react";

function OrderComboOption({item, groupsWithoutMinimum,
                              setSelected,
                              showIgnoredGroups, ignoredGroups, selectOption, selected}) {
    const { t, i18n } = useTranslation("main");
    return <>
        {
            item.OptionGroups.sort((a, b) => {
                if (b.Order < a.Order) {
                    return -1
                }
                if (b.Order > a.Order) {
                    return 1
                }
                return 0
            }).map((optionGroup, index) => (
                <div key={optionGroup.id}>
                    {index !== 0 && <div className="divider"/>}
                    <div
                        className={
                            showIgnoredGroups &&
                            ((optionGroup.IsMandatory &&
                                    ignoredGroups.find(
                                        group => group.id === optionGroup.id
                                    ) !== undefined) ||
                                groupsWithoutMinimum.find(
                                    group => group.OptionGroupID === optionGroup.id
                                ) !== undefined)
                                ? "option-group option-group--error"
                                : "option-group"
                        }
                    >
                        <div className="option-group__label">
                            <img
                                src={optionIcons[Number(optionGroup.OptionGroup.Icon)]}
                                alt="meal"
                            />
                            <div className="img-partner">
                                {optionGroup.Lable}{" "}
                                <span className="option-select-text">
                        {optionGroup.OptionGroup.Max !== 0 &&
                            `${
                                selected.filter(
                                    opt =>
                                        opt.OptionGroupID === optionGroup.OptionGroup.id && opt.ItemID === item.ItemID
                                ).length
                            }/${optionGroup.OptionGroup.Max}`}
                                    {optionGroup.OptionGroup.IsMandatory === true &&
                                        " (חובה) "}
                      </span>
                            </div>
                        </div>
                        <div className="option-group__options">
                            {optionGroup.OptionGroup.Options.map(option => (
                                <div key={option.id} className="option-wrapper">
                                    <div
                                        className={
                                            selected.find(
                                                opt =>
                                                    opt.id === option.id &&
                                                    opt.ItemOptionId === optionGroup.id
                                                    && opt.ItemID === item.ItemID
                                            )
                                                ? "option option--selected"
                                                : "option"
                                        }
                                        onClick={() =>
                                        {
                                            selected.find(
                                                opt =>
                                                    opt.id === option.id &&
                                                    opt.ItemOptionId === optionGroup.id
                                            )
                                                ? setSelected(
                                                    selected.filter(
                                                        opt =>
                                                            !(
                                                                opt.id === option.id &&
                                                                opt.ItemOptionId === optionGroup.id
                                                            )
                                                    )
                                                )
                                                : selectOption(option, optionGroup, item)
                                        }
                                        }
                                    >
                                        <img src={selectedIcon} alt="selected"/>
                                        <span className="option__text">
                            {option.Name}{" "}
                                            {!optionGroup.IsFree &&
                                                option.ClientPrice !== 0 &&
                                                `${t("currency")}${option.ClientPrice}`}
                          </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            ))
        }
    </>
}

export default OrderComboOption;
