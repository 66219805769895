import React, {useState} from "react"
import {useTranslation} from "react-i18next"
import "./MainPage.scss"
import imageFiller from "../../images/image_filler_icon.svg"
import timerIcon from "../../images/timer_icon.svg"
import ItemImageHelper from "../helpers/ItemImageHelper"
import QuantityCounter from "../dish/QuantityCounter"
import {ReactComponent as Cart} from "../../images/header/cart_icon.svg"
import priceIcon from "../../images/price_icon.svg"
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Favorite from '@material-ui/icons/Favorite';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';


function check(id) {
    let favoriteCombos = JSON.parse(localStorage.getItem("favoriteCombos"));
    if (favoriteCombos != null && favoriteCombos.includes(id)) return true; else return false;
}

function ComboDish({
                       selectItem,
                       isOrderTimeOver,
                       name,
                       img,
                       cost,
                       tags,
                       id,
                       isStoreItem,
                       saveToCart,
                       favoriteItem,
                       isPriceDisplayed
                   }) {
    function onImageError(e) {
        e.target.onerror = null
        e.target.className = "dish-image dish-image-filler"
        const sources = e.target.parentNode.children
        e.target.src = sources[0].srcset = sources[1].srcset = imageFiller
    }

    const { t, i18n } = useTranslation("main");
    const [quantity, setQuantity] = useState(1)
    return (<div className="dish">
        <div className={i18n.language === 'en' ? 'dish-favorite-reverse' : 'dish-favorite'}>
            <FormControlLabel
                control=
                    {<Checkbox
                        onChange={favoriteItem}
                        icon={<FavoriteBorder />}
                        checked={check(id)}
                        checkedIcon={<Favorite />}
                        name="checked" />}
            />
        </div>
        <div className="dish-image-wrapper" onClick={selectItem}>
            {img.length ? (
                <picture className="dish-image">
                    <source
                        srcSet={ItemImageHelper.small_webp(img)}
                        type="image/webp"
                    />
                    <source
                        srcSet={ItemImageHelper.small_jpg(img)}
                        type="image/jpg"
                    />
                    <img
                        src={ItemImageHelper.small_jpg(img)}
                        alt="meal"
                        className="dish-image"
                        onError={onImageError}
                    />
                </picture>
            ) : (
                <img className="dish-image dish-image-filler" src={imageFiller} />
            )}
            {isOrderTimeOver && (<div className="dish-image-blocker">
                <div className="dish-image-text-wrapper">
                    <img src={timerIcon} alt="timer"/>
                    {t("main.closefororder")}
                </div>
            </div>)}
        </div>
        <div className={i18n.language === 'en' ? 'dish-image-tags-reverse' : 'dish-image-tags'}>
            {tags.map(tag => tag.Name).map((el, index) => (
                <span className="dish-image-tag" key={index}>
              {el}
            </span>
            ))}
        </div>
        <div className={i18n.language === 'en' ? 'dish-information-reverse' : 'dish-information'} onClick={selectItem}>
            <div>
                <span className="dish-name">{name}</span>
            </div>
            <span className="dish-cost">
                      <img src={priceIcon} alt="price" className="dish-cost__icon"/>
                {t("main.cost")}: {t("currency")}{cost}
            </span>
            {isStoreItem && (<div className="dish-price-and-actions-row">
                <QuantityCounter
                    quantityIsValid={quantity > 0}
                    disabled={isOrderTimeOver}
                    quantity={quantity}
                    setQuantity={setQuantity}
                />
                <div
                    className="order-button"
                    onClick={() => !isOrderTimeOver ? saveToCart(id, name, quantity) : false}
                >
                    <Cart
                        height={10}
                        width={10}
                        className="order-button__cart-icon fill-white"
                    />
                    {t("main.add_to_cart_btn")}
                </div>
            </div>)}
        </div>
    </div>)
}

export default ComboDish
