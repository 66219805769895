import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import Radio from '@material-ui/core//Radio';
import Checkbox from '@material-ui/core//Checkbox';
import "./PaymentSelectionForm.scss"

function PaymentSelectionForm({
    fullAmount,
    selectedAmount,
    onChangeAmount,
    isFullAmountSelected,
    onSelectOption,
    isInvalid,
    amountInputRef,
    isFullAmountHide,
    selectedOptions,
    onSelectCheckBox,
    computedSelectedOptions
}) {
    const { t } = useTranslation("main");

    const renderOptions = () => {
        if (computedSelectedOptions && computedSelectedOptions.length > 1) {
            return (
                <div className="payment-selection-form__option-selector">
                    <div className="payment-selection-form__daily-budgets">
                        {computedSelectedOptions.map((budget) => (
                            <div
                                key={budget.Date}
                                className={`payment-selection-form__option__budget ${selectedAmount === budget.Budget && isInvalid ? "--error" : ""
                                    }`}
                                onClick={() => {
                                    if (budget.displayedBudget > 0)
                                        onSelectCheckBox(budget.Date)
                                }}
                            >
                                <Checkbox
                                    disableRipple
                                    checked={selectedOptions.some((option) =>option.date === budget.Date)}
                                    onChange={() => {
                                        if (budget.displayedBudget > 0)
                                            onSelectCheckBox(budget.Date)
                                    }}
                                />
                                <span className="payment-selection-form__option-text">
                                    {`₪${budget.displayedBudget}`}
                                </span>
                                <span className="payment-selection-form__option-text">
                                    {`${budget.Date.split("T")[0]}`}
                                </span>
                            </div>
                        ))}
                    </div>

                    {/* 
                    <div
                        className="payment-selection-form__option"
                        onClick={() => onSelectOption("part")}
                    >
                        <Radio
                            disableRipple   
                            checked={selectedOption === "part"}
                            onChange={() => onSelectOption("part")}
                        />
                        <span className="payment-selection-form__option-text">
                            {t("order.payment_selection_form.partial_amount")}
                        </span>
                    </div>    
                    */}
                </div>
            );
        }

        // Default options if no dailyBudgets are provided
        return (
            <div className="payment-selection-form__option-selector">
                <div
                    className={`payment-selection-form__option ${isFullAmountSelected && isInvalid ? "--error" : ""
                        }`}
                    onClick={() => onSelectOption("all")}
                >
                    <Radio
                        disableRipple
                        checked={isFullAmountSelected}
                        onChange={() => onSelectOption("all")}
                    />
                    <span className="payment-selection-form__option-text">
                        {isFullAmountHide ? t("order.payment_selection_form.full") : `₪${fullAmount}`}
                    </span>
                </div>
                <div
                    className="payment-selection-form__option"
                    onClick={() => onSelectOption("part")}
                >
                    <Radio
                        disableRipple
                        checked={!isFullAmountSelected}
                        onChange={() => onSelectOption("part")}
                    />
                    <span className="payment-selection-form__option-text">
                        {t("order.payment_selection_form.partial_amount")}
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div className="payment-selection-form">
            {renderOptions()}
            <div
                ref={amountInputRef}
                className="payment-selection-form__amount-input-wrapper"
            >
                <input
                    type="number"
                    value={selectedAmount}
                    disabled={isFullAmountSelected}
                    onChange={(event) => onChangeAmount(event.target.value)}
                    placeholder={t("order.payment_selection_form.enter_amount")}
                    className={`payment-selection-form__amount-input ${isInvalid ? "--error" : ""
                        }`}
                    dir="ltr"
                />
            </div>
        </div>
    );
}

export default PaymentSelectionForm
