import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import NavItem from "./NavItem"
import historyIcon from "../../images/history_icon.svg"
import mainIcon from "../../images/main_icon.svg"
import storeIcon from "../../images/store_icon.svg"
import profileIcon from "../../images/profile_icon.svg"
import supportIcon from "../../images/support_icon.svg"
import faqIcon from "../../images/faq_icon.svg"
import termsIcon from "../../images/terms_icon.svg"
import logoutIcon from "../../images/logout_icon.svg"
import logoutBigIcon from "../../images/logout_icon_big.svg"
import closeIcon from "../../images/header/close-icon.svg"
import mmenuLogoIcon from "../../images/header/mmenu_logo.png"
import { logout } from "../../redux/actions/loginActions"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import "./Navbar.scss"
import { setSearchUser } from "../../redux/actions/searchActions"
import { selectCustomer } from "../../redux/actions/adminActions"

function Navbar({
    open,
    setOpen,
    setShowContactUs,
    logout,
    setSearchUser,
    selectCustomer,
    userInfo,
    ...props
}) {
    const { t, i18n } = useTranslation("main");
    const isAuthorized = userInfo ? userInfo.confirmedLogin : false

    const isCordova = window.hasOwnProperty("cordova")
    const [appVersion, setAppVersion] = useState("")

    const isRoleDelivery = isAuthorized && userInfo.Role === "Delivery"
    const isRoleAdmin = isAuthorized && userInfo.Role === "Admin"
    const [showLangDropdown, setShowLangDropDown] = useState(false)
    const isRoleDepartmentUser =
        isAuthorized && userInfo.Role === "Department User"
        const languages = [
    { code: "en", name: "English", img: "../../images/en.svg" },
    { code: "he", name: "עברית", img: "../../images/he.svg" },
];

    const menuItems = [
        {
            name: `${t("navigation.history")}`,
            icon: historyIcon,
            url: "history",
            handleClick: () => {
                setOpen(false)
            },
            isHidden: isRoleDepartmentUser || isRoleDelivery
        },

        {
            name: `${t("navigation.main")}`,
            icon: mainIcon,
            url: "main",
            handleClick: () => {
                setOpen(false)
            },
            isHidden: isRoleDelivery
        },
        {
            name: `${t("navigation.store")}`,
            icon: storeIcon,
            url: "store",
            handleClick: () => {
                setOpen(false)
            },
            isHidden: isRoleDelivery || process.env.REACT_APP_FLAVOR == 'NY'
        },
        {
            name: `${t("navigation.profile")}`,
            icon: profileIcon,
            url: "account",
            handleClick: () => {
                setOpen(false)
            },
            isHidden: isRoleDepartmentUser || isRoleAdmin || isRoleDelivery
        },
        {
            name: `${t("navigation.support")}`,
            icon: supportIcon,
            url: "support",
            handleClick: event => {
                event.preventDefault()
                setShowContactUs(true)
                setOpen(false)
            }
        },
        {
            name: `${t("navigation.faq")}`,
            icon: faqIcon,
            url: "faq",
            handleClick: () => {
                setOpen(false)
            }
        },
        {
            name: `${t("navigation.logout")}`,
            icon: logoutBigIcon,
            url: "logout",
            handleClick: () => {
                setOpen(false)
                setSearchUser({})
                selectCustomer(null)
                logout()
            }
        }
    ]
    function closeLangDropdown(event) {
        event.preventDefault()
        setShowLangDropDown(false)
        document.removeEventListener("click", closeLangDropdown)
    }

    useState(() => {
        if (isCordova) {
            window.cordova.getAppVersion.getVersionNumber(result => {
                setAppVersion(result)
            })
        }
    }, [])

    useEffect(() => {
        if (showLangDropdown) {
            document.addEventListener("click", closeLangDropdown)
        }
    }, [showLangDropdown])


    function updateLanguage(lang) {
        localStorage.setItem('lang', lang);
        window.location.reload();
    }

    return (
        <div className={open ? "menu-wrapper" : "menu-wrapper close"}>
            <div className="menu-bg">
                <div className={(i18n.language === 'en' ? "menu-reverse" : "menu")}>
                    {isAuthorized ? (
                        <div className="menu-content">
                            <div className="menu-top">
                                <img
                                    src={closeIcon}
                                    alt="open"
                                    onClick={() => setOpen(!open)}
                                />
                                <img src={mmenuLogoIcon} alt="Dish tag" />
                            </div>
                            <div className="menu-name__logo">
                                <span>
                                    {t("navigation.greeting_to_user")}, {userInfo.FirstName}
                                </span>
                            </div>

                            <nav className="menu-nav">
                                {menuItems
                                    .filter(el => !el.isHidden)
                                    .map((el, index) => (
                                        <NavItem
                                            name={el.name}
                                            img={el.icon}
                                            url={el.url}
                                            key={index}
                                            handleClick={el.handleClick}
                                        />
                                    ))}
                            </nav>
                        </div>
                    ) : null}

                    <div className="menu-footer">
                        <div className={(i18n.language === 'en' ? "menu-logout-reverse" : "menu-logout")}>
                            <div className="dropdown-menu mx-2">
                                <div
                                    className={`dropdown-handler-wrapper ${showLangDropdown ? "expanded" : ""}`}
                                    onClick={() => setShowLangDropDown(!showLangDropdown)}
                                >
                                    <div
                                        className="dropdown-handler"
                                    >
                                        <span className="dropdown-label" dir={i18n.dir()}>
                                            <div className="dropdown-title">
                                                {t("navigation.lang")}
                                            </div>
                                        </span>
                                    </div>
                                    <ul
                                        className="dropdown-list"
                                        style={{
                                            height: showLangDropdown ? 41 * 2 : 0,
                                        }}
                                    >
                                        <li
                                            className="dropdown-item"
                                            onClick={() => updateLanguage("en")}
                                        >
                                            <div className="enLang-img"></div>
                                            English
                                        </li>
                                        <li
                                            className="dropdown-item"
                                            onClick={() => updateLanguage("he")}
                                        >
                                            <div className="heLang-img"></div>
                                            עברית
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <span>{t("navigation.language")}</span>
                        </div>

                        <div className="menu-description">
                            {isCordova && <span>{appVersion}</span>}
                            <div className="links-wrapper">
                                <Link
                                    className="menu-terms"
                                    to="/terms"
                                    onClick={() => setOpen(false)}
                                >
                                    <span>{t("navigation.terms")}</span>
                                </Link>
                                <Link
                                    className="menu-terms"
                                    to="/privacy"
                                    onClick={() => setOpen(false)}
                                >
                                    <span>{t("navigation.privacy")}</span>
                                </Link>
                            </div>
                            <img src={termsIcon} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        userInfo: state.login.userInfo
    }
}

const mapDispatchToProps = {
    logout,
    setSearchUser,
    selectCustomer
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
